/* eslint-disable jsx-a11y/alt-text */
import defa from "./../../../../assets/images/dashboard/kunder/defa.jpg";
import jackie from "./../../../../assets/images/dashboard/kunder/jackie.jpg";
import cotton from "./../../../../assets/images/dashboard/kunder/cotton.jpg";
import kia from "./../../../../assets/images/dashboard/kunder/kia.jpg";
import danads from "./../../../../assets/images/dashboard/kunder/danads.jpg";
import hermes from "./../../../../assets/images/dashboard/kunder/hermes.jpg";
import celsius from "./../../../../assets/images/dashboard/kunder/celsius.jpg";
import video1 from "../../../../assets/TopPage/1.mp4";
import video2 from "../../../../assets/TopPage/2.mp4";
import video3 from "../../../../assets/TopPage/3.mp4";
import { useRef } from "react";
import konsulting from "./../../../../assets/images/konsulting.jpg"
import seo from "./../../../../assets/images/seo.jpg"
import sem from "./../../../../assets/images/sem.jpg"
import some from "./../../../../assets/images/some.jpg"
import content from "./../../../../assets/images/content.jpg"
import webbutveckling from "./../../../../assets/images/webbutveckling.jpg"





function ChosenProducts() {
  const videoRef = useRef(null);
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);

  return (
<section className="w-full hover:cursor-pointer flex h-full items-center justify-center flex-col">
  <div className="flex w-full h-full flex-col">
    <div className="flex w-full grid grid-cols-3 md:grid-cols-1">
      <div className="relative">
        <a href="/reklam" className="block w-full h-full">
          <img src={konsulting} alt="Reklamfilm" className="w-full h-full object-cover" />
          <p className="absolute bottom-4 left-4 font-bold lg:text-[30px] text-[50px] sm:text-[50px] text-white">
            Konsulting
          </p>
        </a>
      </div>
      <div className="relative">
        <a href="/eventfilm" className="block w-full h-full">
          <img src={seo} alt="Eventfilm" className="w-full h-full object-cover" />
          <p className="absolute bottom-4 left-4 font-bold lg:text-[30px] text-[50px] sm:text-[50px] text-white">
            SEO
          </p>
        </a>
      </div>
      <div className="relative">
        <a href="/coporate" className="block w-full h-full">
          <img src={sem} alt="Corporate" className="w-full h-full object-cover" />
          <p className="absolute bottom-4 left-4 font-bold lg:text-[30px] text-[50px] sm:text-[50px] text-white">
            SEM
          </p>
        </a>
      </div>
    </div>
    <div className="flex w-full grid grid-cols-3 md:grid-cols-1">
      <div className="relative">
        <a href="/reklam" className="block w-full h-full">
          <img src={some} alt="Reklamfilm" className="w-full h-full object-cover" />
          <p className="absolute bottom-4 left-4 font-bold lg:text-[30px] text-[50px] sm:text-[50px] text-white">
            SoMe
          </p>
        </a>
      </div>
      <div className="relative">
        <a href="/eventfilm" className="block w-full h-full">
          <img src={content} alt="Eventfilm" className="w-full h-full object-cover" />
          <p className="absolute bottom-4 left-4 font-bold lg:text-[30px] text-[50px] sm:text-[50px] text-white">
            Content Produktion
          </p>
        </a>
      </div>
      <div className="relative">
        <a href="/coporate" className="block w-full h-full">
          <img src={webbutveckling} alt="Corporate" className="w-full h-full object-cover" />
          <p className="absolute bottom-4 left-4 font-bold lg:text-[30px] text-[50px] sm:text-[50px] text-white">
            Webbutveckling
          </p>
        </a>
      </div>
      </div>
<div className="w-full flex flex-col justify-center items-center pt-[10rem] pb-[2rem]">
  <div className="w-[60%] grid grid-cols-4 sm:grid-cols-2 sm:w-[80%] gap-12">
    <div
      className="h-[131px] bg-no-repeat"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .0), rgba(0,0,0, .0)), url(${defa})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
      }}
    />
    <div
      className="h-[131px] bg-no-repeat"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .0), rgba(0,0,0, .0)), url(${jackie})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
      }}
    />
    <div
      className="h-[131px] bg-no-repeat"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .0), rgba(0,0,0, .0)), url(${cotton})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
      }}
    />
    <div
      className="h-[131px] bg-no-repeat"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .0), rgba(0,0,0, .0)), url(${kia})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
      }}
    />
  </div>
</div>

<div className="w-full flex flex-col justify-center items-center pt-[0rem] pb-[2rem]">
  <div className="w-[60%] grid grid-cols-3 sm:grid-cols-2 sm:w-[80%] gap-12">
    <div
      className="h-[131px] bg-no-repeat"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .0), rgba(0,0,0, .0)), url(${danads})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
      }}
    />
    <div
      className="h-[131px] bg-no-repeat"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .0), rgba(0,0,0, .0)), url(${hermes})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
      }}
    />
    <div
      className="h-[131px] bg-no-repeat"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .0), rgba(0,0,0, .0)), url(${celsius})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
      }}
    />
    
  </div>
  
</div>

</div>
    </section>
  );
}

export default ChosenProducts;
