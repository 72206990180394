/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-lone-blocks */

import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./../../index.css";

import Logo from './../../assets/images/logo.png';

function Header() {
  const navRef = useRef(null);



  return (
    <>
      <header
        id="navbar"
        className={`w-full items-center transparent-nav flex flex-col fixed z-50 transition-all duration-200 ease-linear -top-[6rem]`}
        ref={navRef}
      >
       
      </header>
    </>
  );
}

export default Header;
